import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Layout/Layout';
import Section from '../components/Layout/Section/Section';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';

import {PageTemplateStyled} from '../templates/styles';
import Text from '../components/Elements/Text/Text';
import Headline from '../components/Elements/Headline/Headline';

class VerifyRegistration extends React.Component {
	render() {
		const {location} = this.props;
		return (
			<>
				<Meta title={appendSeoTitleSuffix('Freischaltung Deines Benutzerkontos')} />
				<Layout location={location}>
					<PageTemplateStyled>
						<Section showPadding>
							<Headline as="h1" center text="Vielen Dank für deine Registrierung!" />
							<Text
								content="Bitte überprüfe nun deine E-Mails und bestätige
								deine Anmeldung. Solltest du die E-Mail zur Bestätigung deiner Anmeldung nicht finden
								können, überprüfe bitte den Spam-Ordner deines E-Mail Accounts."
								center
								maxWidth={850}
							/>
						</Section>
					</PageTemplateStyled>
				</Layout>
			</>
		);
	}
}

VerifyRegistration.propTypes = {
	location: PropTypes.shape({pathname: PropTypes.string}).isRequired,
};

export default VerifyRegistration;
